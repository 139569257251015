export const reviewsMainEn = [
  'dvorkovich',
  'bologan',
  'akalanka',
  'sakotic',
  'talapker',
  'stefanov',
  'ibraev',
  'kapaev',
  //'vatanov',
  //'isaeva',
  //'abdukhalikov',
  //'umarov',
  'khalil',
  //'zakharov',
  //'moiseev',
  'albaiz',
  //'sherbin',
  //'manakov',
  'radic',
  'wadhahi',
  'aguilar',
  'bodnani',
  'kamalov',
  'doctor_chess',
  'yakubboev',
  'kalnins',
  'habash',
];

export const reviewsMain = [
  'dvorkovich',
  'akalanka',
  'bologan',
  'sakotic',
  'talapker',
  'stefanov',
  'habash',
  'ibraev',
  'kapaev',
  //'vatanov',
  //'isaeva',
  //'abdukhalikov',
  //'umarov',
  'khalil',
  'zakharov',
  'moiseev',
  'kamalov',
  'sherbin',
  'manakov',
  'radic',
  'wadhahi',
  'aguilar',
  'bodnani',
  'albaiz',
  'doctor_chess',
  'yakubboev',
  'kalnins',
];

export const reviewsOrganizersEn = [
  'dvorkovich',
  'bologan',
  'akalanka',
  'talapker',
  'stefanov',
  'ibraev',
  'kapaev',
  //'vatanov',
  //'isaeva',
  //'abdukhalikov',
  //'umarov',
  //'khalil',
  //'zakharov',
  //'moiseev',
  //'albaiz',
  //'sherbin',
  //'manakov',
  'radic',
  //'wadhahi',
  'aguilar',
  //'bodnani',
  'kamalov',
  //'doctor_chess',
  'yakubboev',
  'kalnins',
  //'habash',
];

export const reviewsOrganizers = [
  'dvorkovich',
  'bologan',
  'akalanka',
  'talapker',
  'stefanov',
  'ibraev',
  'kapaev',
  //'vatanov',
  //'isaeva',
  //'abdukhalikov',
  //'umarov',
  //'khalil',
  'zakharov',
  'moiseev',
  'kamalov',
  'sherbin',
  'manakov',
  'radic',
  //'wadhahi',
  'yakubboev',
  'aguilar',
  //'bodnani',
  //'albaiz',
  //'doctor_chess',
  'kalnins',
  //'habash',
];

export const reviewsVar = [
  'dvorkovich',
  'isakov',
  'kamalov',
  //'abdukhalikov',
  //'umarov',
  //'vatanov',
  'radic',
  'wadhahi',
  'stefanov',
  'aguilar',
  'khalil',
  'bodnani',
  'albaiz',
  'doctor_chess',
  'kalnins',
  'yakubboev',
  //'habash',
];

export const reviewsData = {
  dvorkovich: {
    image: 'dvorkovich.png',
    flag: 'fide.svg',
  },
  talapker: {
    image: 'talapker.jpg',
    flag: 'kyrgyzstan.svg',
  },
  akalanka: {
    image: 'akalanka.png',
    flag: 'sri_lanka.png',
  },
  sakotic: {
    image: 'sakotic.jpg',
    flag: 'serbia.svg',
  },
  bologan: {
    image: 'bologan.jpg',
    flag: 'fide.svg',
  },
  ibraev: {
    image: 'ibraev.jpg',
    flag: 'kazakhstan.png',
  },
  kapaev: {
    image: 'kapaev.jpg',
    flag: 'kazakhstan.png',
  },
  kamalov: {
    image: 'kamalov.png',
    flag: 'uzbekistan.svg',
  },
  vatanov: {
    image: 'vatanov.png',
    flag: 'tajikistan.svg',
  },
  isaeva: {
    image: '',
    flag: 'turkmenistan.png',
  },
  abdukhalikov: {
    image: 'abdukhalikov.png',
    flag: 'uzbekistan.svg',
  },
  umarov: {
    image: 'umarov.png',
    flag: 'uzbekistan.svg',
  },
  moiseev: {
    image: 'moiseev.png',
    flag: 'russia.svg',
  },
  zakharov: {
    image: 'zakharov.png',
    flag: 'russia.svg',
  },
  sherbin: {
    image: 'sherbin.png',
    flag: 'russia.svg',
  },
  manakov: {
    image: 'manakov.png',
    flag: 'russia.svg',
  },
  isakov: {
    image: 'isakov.png',
    flag: 'russia.svg',
  },
  radic: {
    image: 'radic.png',
    flag: 'croatia.png',
  },
  wadhahi: {
    image: 'wadhahi.png',
    flag: 'oman.svg',
  },
  aguilar: {
    image: 'aguilar.png',
    flag: 'mexico.svg',
  },
  khalil: {
    image: 'khalil.png',
    flag: 'lebanon.svg',
  },
  bodnani: {
    image: 'bodnani.png',
    flag: 'morocco.svg',
  },
  albaiz: {
    image: 'albaiz.png',
    flag: 'saudi_arabia.png',
  },
  doctor_chess: {
    image: 'doctor_chess.png',
    flag: 'egypt.svg',
  },
  yakubboev: {
    image: 'yakubboev.png',
    flag: 'uzbekistan.svg',
  },
  stefanov: {
    image: 'stefanov.png',
    flag: 'bulgaria.svg',
  },
  kalnins: {
    image: 'kalnins.png',
    flag: 'fide.svg',
  },
  habash: {
    image: 'habash.png',
    flag: 'lebanon.svg',
  },
};
